import { GatsbyImage, getImage, getSrc, getSrcSet } from "gatsby-plugin-image"
import React, { Component, Fragment } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

class LightboxWoodworking extends Component {
    state = {
        showLightbox: false,
        selectedImage: 0,
    }

    componentDidMount = () => {
        window.addEventListener('keyup', this.handleKeyUp, false)
        function handleResize(e) {
            let element = document.getElementById(`project-grid-woodworking`);
            function Dimension(el) {
                var elHeight = el.offsetHeight;
                elHeight += parseInt(window.getComputedStyle(el).getPropertyValue('margin-top'));
                elHeight += parseInt(window.getComputedStyle(el).getPropertyValue('margin-bottom'));
                return elHeight;
            }
            var elHeight;
            var postListItems = document.getElementsByClassName(`post-list-item-woodworking`);
            let totalHeight = 0;
            for (const element of postListItems) {
                totalHeight += Dimension(element) + 10
            }

            if (window.innerWidth < 950) {
                elHeight = totalHeight / 1.7;
            } else {
                elHeight = totalHeight / 2.7;
            }
            element.style.height = elHeight + "px";
        }
        handleResize() // run this once
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }

    componentWillUnmount = () => {
        window.removeEventListener('keyup', this.handleKeyUp, false)
    }

    handleTouchStart(event) {
        this.setState({
            initialClientX: event.nativeEvent.touches[0].clientX,
            initialClientY: event.nativeEvent.touches[0].clientY
        });
    }

    handleTouchMove(event) {
        this.setState({
            finalClientX: event.nativeEvent.touches[0].clientX,
            finalClientY: event.nativeEvent.touches[0].clientY
        });
    }

    handleTouchEnd() {
        if ((this.state.finalClientX - this.state.initialClientX) > 30) {
            if (this.state.selectedImage > 0) {
                this.goBack()
            }
        } else if ((this.state.finalClientX - this.state.initialClientX) < -30) {
            if (this.state.selectedImage < this.props.images.length - 1) {
                this.goForward()
            }
        }

        this.setState({
            initialClientX: 0,
            finalClientX: 0
        });
    }

    handleClick = (e, index) => {
        var body = document.body;
        body.className += " lock-screen";
        e.preventDefault()
        this.setState({ showLightbox: !this.state.showLightbox, selectedImage: index })
    }

    closeModal = () => {
        var body = document.body;
        body.classList.remove("lock-screen");
        this.setState({ showLightbox: false })
    }

    goBack = () => {
        this.setState({ selectedImage: this.state.selectedImage - 1 })
    }

    goForward = () => {
        this.setState({ selectedImage: this.state.selectedImage + 1 })
    }

    handleKeyUp = e => {
        e.preventDefault()
        const { keyCode } = e
        if (this.state.showLightbox) {
            if (keyCode === 37) {
                // Left Arrow Key
                if (this.state.selectedImage > 0) {
                    this.setState({ selectedImage: this.state.selectedImage - 1 })
                }
            }
            if (keyCode === 39) {
                // Right Arrow Key
                if (this.state.selectedImage < this.props.images.length - 1) {
                    this.setState({ selectedImage: this.state.selectedImage + 1 })
                }
            }
            if (keyCode === 27) {
                // Escape key
                this.setState({ showLightbox: false })
            }
        }
    }

    render() {
        const { images, category } = this.props
        const { showLightbox, selectedImage } = this.state
        const specialIdProjectGrid = "project-grid-" + category
        var emdash1 = " - "
        if (images[selectedImage].frontmatter.location === null) {
            emdash1 = ""
        }
        return (
            <Fragment>
                <ul className="project-grid" id={specialIdProjectGrid}>
                    {images.map((img, i) => {
                        if (img.frontmatter.category === category) {
                            const actualImg = getImage(img.frontmatter.featuredfile1);
                            const specialClassPostListItem = "post-list-item post-list-item-" + category
                            var emdash2 = (img.frontmatter.location === null) ? "" : " — "
                            if (i === 0) {
                                return (
                                    <li key={img.fields.slug}
                                        className={specialClassPostListItem}
                                        itemScope
                                        itemType="http://schema.org/Article"
                                    >
                                        <div role="none" onClick={e => this.handleClick(e, i)}>
                                            <img className="specialImg" src={getSrc(img.frontmatter.featuredfile1)} srcSet={getSrcSet(img.frontmatter.featuredfile1)} alt={img.frontmatter.dek} />
                                            <div className="caption"><span className="capitalic">{img.frontmatter.location}</span> {emdash2} {img.frontmatter.dek}</div>
                                            <div className="subcaption">{img.frontmatter.responsibilities}</div>
                                        </div>
                                    </li>
                                )
                            } else {
                                return (
                                    <li key={img.fields.slug}
                                        className={specialClassPostListItem}
                                        itemScope
                                        itemType="http://schema.org/Article"
                                    >
                                        <div role="none" onClick={e => this.handleClick(e, i)}>
                                            <GatsbyImage image={actualImg} alt={img.frontmatter.dek} />
                                            <div className="caption"><span className="capitalic">{img.frontmatter.location}</span> {emdash2} {img.frontmatter.dek}</div>
                                            <div className="subcaption">{img.frontmatter.responsibilities}</div>
                                        </div>
                                    </li>
                                )
                            }
                        } else {
                            return (null)
                        }
                    })}
                </ul>

                <LightboxModal role="modal" onClick={this.closeModal} visible={showLightbox} onKeyUp={e => this.handleKeyDown(e)} onTouchStart={e => this.handleTouchStart(e)} onTouchMove={e => this.handleTouchMove(e)} onTouchEnd={e => this.handleTouchEnd(e)}>
                    <div className="closeButton">X</div>
                    <LightboxContent>
                        <StyledImg image={getImage(images[selectedImage].frontmatter.featuredfile1)} alt={images[selectedImage].frontmatter.dek} />
                        <div className="mobile-img-info">
                            <div className="caption"><span className="capitalic">{images[selectedImage].frontmatter.location}</span> {emdash1} {images[selectedImage].frontmatter.dek}</div>
                            <div className="subcaption">{images[selectedImage].frontmatter.responsibilities}</div>
                        </div>
                    </LightboxContent>
                </LightboxModal>
            </Fragment>
        )
    }
}


const LightboxModal = styled.div`
  z-index: 9990;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  transition: all .3s ease-out;
`
const LightboxContent = styled.div`
  max-width: 700px;
  position:relative;
  width: 90%;
  cursor: pointer;
`

const StyledImg = styled(GatsbyImage)`
  width: 100%;
  z-index: 9992;
  cursor: pointer;
  max-height: 90vh;
`

LightboxWoodworking.propTypes = {
    images: PropTypes.array.isRequired,
}

export default LightboxWoodworking