import * as React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Lightbox from '../components/Lightbox'
import LightboxWoodworking from '../components/LightboxWoodworking'
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Ash Ngu`
  const posts = data.allMarkdownRemark.nodes
  const photographyPosts = posts.filter(image => image.frontmatter.category === "photography");
  const woodworkingPosts = posts.filter(image => image.frontmatter.category === "woodworking");

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Portfolio" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/..." (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Portfolio" />
      <Bio />

      <section className="journalism">
        <div className="category-title" id="journalism">Journalism</div>
        <ul className="project-grid" id="project-grid-journalism">
          {posts.map(post => {
            if (post.frontmatter.category === "journalism") {
              const image = getImage(post.frontmatter.featuredfile1)
              return (
                <li key={post.fields.slug}
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <OutboundLink href={post.frontmatter.link} target="_blank" rel="noreferrer">
                    <GatsbyImage image={image} alt={post.frontmatter.hed} />
                    <div className="hed">
                      {post.frontmatter.hed}
                    </div>
                    <section>
                      <div className="dek">{post.frontmatter.dek}</div>
                      <div className="responsibilities">{post.frontmatter.responsibilities}</div>
                    </section>
                  </OutboundLink>
                </li>
              )
            } else {
              return (null);
            }
          })}
        </ul>
      </section>

      <section className="photography">
        <div className="category-title" id="photography">Photography</div>
        <Lightbox images={photographyPosts} category="photography" />
      </section>

      <section className="woodworking">
        <div className="category-title" id="woodworking">Woodworking</div>
        <LightboxWoodworking images={woodworkingPosts} category="woodworking" />
      </section>

    </Layout >
  )
}

export default BlogIndex
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          newsroom
          title
          category
          hed
          location
          articledate
          dek
          responsibilities
          link
          featuredfile1 {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`
